import {Controller} from "@hotwired/stimulus"
import "tom-select/dist/css/tom-select"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["select", "enable", "row", "min", "max"]

  connect() {
    if (this.hasSelectTarget) {
      this.enableTS()
    }

    this.setRowAvailability()
    this.setRowCount()
  }

  enableTS() {
    new TomSelect(this.selectTarget, {
      maxItems: null,
    })
  }

  handleCriteriaEnablingChange() {
    this.setRowAvailability()
    this.setRowCount()
  }

  setRowAvailability() {
    if (this.enableTarget.checked) {
      this.rowTarget.classList.remove("disable")
      this.setMinMaxAvailability(false)
    } else {
      this.rowTarget.classList.add("disable")
      this.setMinMaxAvailability(true)
    }
  }

  setRowCount() {
    const section = this.enableTarget.closest(".filter-section")
    const bagde = section.querySelector(".criteria-count")
    const count = section.querySelectorAll(".criteria:checked").length

    bagde.innerHTML = count
  }

  setMinMaxAvailability(value) {
    if (this.hasMinTarget && this.hasMaxTarget) {
      this.minTarget.disabled = value
      this.maxTarget.disabled = value
    }
  }
}
