import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["list"]

  connect() {
    this._initializeSortable()

    this.blocksAddEvent = document.addEventListener("blocks:add", this._onAdd)
    this.blocksUpdateEvent = document.addEventListener("blocks:update", this._onUpdate)
  }

  disconnect() {
    if (this.blocksAddEvent) document.removeEventListener("blocks:add", this._onAdd)
    if (this.blocksUpdateEvent) document.removeEventListener("blocks:update", this._onUpdate)
  }

  add(event) {
    event.preventDefault()

    const templateKey = event.currentTarget.dataset.templateKey
    const template = document.querySelector(`template#${templateKey}`)
    const newBlock = this._createBlock(template, this.listTarget.children.length)
    let ancestor = event.target.closest(".block-item")

    if (ancestor === null) {
      this.listTarget.insertBefore(newBlock, event.target.closest(".blocks-actions").nextSibling)
    } else {
      this.listTarget.insertBefore(newBlock, ancestor.nextSibling)
    }


    const addEvent = new Event("blocks:add")
    document.dispatchEvent(addEvent)
  }

  remove(event) {
    event.preventDefault()

    const button = event.currentTarget
    const item = button.closest(".block-item")
    const input = item.querySelector(".block-item-input-destroy")

    if (input.value === "false") {
      input.value = "true"
      item.style.opacity = 0.5
    } else {
      input.value = "false"
      item.style.opacity = 1
    }

    const removeEvent = new Event("blocks:remove")
    document.dispatchEvent(removeEvent)
  }

  _createBlock = (template, index) => {
    let element = document.createElement("div")
    element.className = "block-item"
    let html = template.innerHTML
    html = html.replace(/\[__.+?__\]/g, "[" + index + "]")
    html = html.replace(/___NEW__.+?___/g, "_" + index + "_")
    element.innerHTML = html
    return element
  }

  _initializeSortable = () => {
    const options = {
      sort: true,
      ghostClass: "is-ghost",
      handle: ".block-item-handler",
      onUpdate: event => {
        const updateEvent = new Event("blocks:update")
        document.dispatchEvent(updateEvent)
      },
    }
    new Sortable(this.listTarget, options)
  }

  _onAdd = () => {
    this._updatePositions()
  }

  _onUpdate = () => {
    this._updatePositions()
  }

  _updatePositions = () => {
    const items = document.querySelectorAll("input.block-item-input-position")
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      item.value = i
    }
  }
}
