import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["form", "container", "url"]

  connect() {
    this.form = this.formTarget
    this.container = this.containerTarget
    this.url = this.urlTarget.dataset.previewableUrl
  }

  load(event) {
    event.preventDefault()

    this.fetchPreview()
  }

  fetchPreview = async () => {
    try {
      const formData = new FormData(this.form)

      const CSRFParam = document.querySelector('meta[name="csrf-param"]').content
      const CSRFToken = document.querySelector('meta[name="csrf-token"]').content

      formData.append(CSRFParam, CSRFToken)
      formData.append("_method", "post")

      const response = await fetch(this.url, {
        method: "POST",
        credentials: "same-origin",
        body: formData,
      })
      const responseText = await response.text()
      this.container.contentWindow.document.open()
      this.container.contentWindow.document.write(responseText)
      this.container.contentWindow.document.close()

      this.container.classList.add("active")
    } catch (e) {
      alert(e)
    }
  }
}
