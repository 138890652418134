import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["body", "form"]

  initialize() {
    new Sortable(
      this.bodyTarget,
      {
        sort: true,
        filter: ".cannot_sort",
        onUpdate: (event) => {
          const children = this.bodyTarget.children
          let ids = []

          for(let child of children) {
            ids.push(child.dataset.elementId)
          }
          this.submitForm(ids)
        },
      },
    )
  }

  async submitForm(ids) {
    const formData = new FormData(this.formTarget);
    for(let id of ids) {
      formData.append("ids[]", id)
    }

    try {
      const response = await fetch(this.formTarget.action, {
        method: "POST",
        credentials: "same-origin",
        body: formData
      });

      const data = await response.json();
      return data;
    } catch(e) {
      alert("Une erreur a empêchée la liste d'être trié")
    }
  }
}
