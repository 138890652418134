import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["removeFilter"]

  connect() {}

  handleRemoveFilterChange() {
    const filter = this.removeFilterTarget.closest(".filter-container")
    const accordion = filter.querySelector(".filter-accordion")

    if (this.removeFilterTarget.checked) {
      accordion.classList.add("disable")
      filter.querySelector(".remove-filter-button").classList.add("btn-primary")
    } else {
      accordion.classList.remove("disable")
      filter.querySelector(".remove-filter-button").classList.remove("btn-primary")
    }
  }
}
