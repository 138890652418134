import CookieConsentApi from "cookie-consent-api"

const cookieConsent = new CookieConsentApi({
  cookieName: "cookie_consent_settings",
  cookieDuration: 365,
  cookieDomain: "localhost",
  services: ["youtube"],
})

document.addEventListener("turbolinks:load", () => {
  const embedIframeElement = document.querySelector(".embed-iframe")
  if (!cookieConsent.isAccepted("youtube") && embedIframeElement) hideContent()
  if (!cookieConsent.isConfigured("youtube") && embedIframeElement) showBanner()

  cookieConsent.on("accept", (service) => {
    if (service === "youtube") showContent()
  })

  // Action : accept youtube service
  document.querySelector(".cookie-consent-btn-accept").addEventListener("click", () => {
    cookieConsent.accept("youtube")
  })

  // Action : refuse youtube service
  document.querySelector(".cookie-consent-btn-refuse").addEventListener("click", () => {
    cookieConsent.refuse("youtube")
    hideBanner()
  })

  // Action : open settings panel
  document.querySelectorAll(".cookie-consent-btn-open-settings").forEach((element) => {
    element.addEventListener("click", () => {
      showConsentSettingsPanel()
    })
  })

  // Action : close settings panel
  document.querySelector(".cookie-consent-btn-close-settings").addEventListener("click", () => {
    hideConsentSettingsPanel()
  })

  // Action : refuse youtube service in settings panel
  document
    .querySelector(".cookie-consent-settings-panel-btn-refuse")
    .addEventListener("click", () => {
      cookieConsent.refuse("youtube")
      hideContent()
    })

  // Action : accept youtube service in settings panel
  document
    .querySelector(".cookie-consent-settings-panel-btn-accept")
    .addEventListener("click", () => {
      cookieConsent.accept("youtube")
    })
})

const hideContent = () => {
  document.querySelectorAll(".embed-thumbnail").forEach((element) => {
    element.classList.add("show")
  })

  document.querySelectorAll(".embed-iframe").forEach((element) => {
    element.classList.add("hide")
  })

  toggleActiveButton({on: "refuse", off: "accept"})
}

const showContent = () => {
  document.querySelector(".cookie-consent-banner").classList.remove("show")

  document.querySelectorAll(".embed-thumbnail").forEach((element) => {
    element.classList.remove("show")
  })

  document.querySelectorAll(".embed-iframe").forEach((element) => {
    element.classList.remove("hide")
  })
  toggleActiveButton({on: "accept", off: "refuse"})
}

const hideBanner = () => document.querySelector(".cookie-consent-banner").classList.remove("show")

const showBanner = () => document.querySelector(".cookie-consent-banner").classList.add("show")

const showConsentSettingsPanel = () =>
  document.querySelector(".cookie-consent-settings-panel").classList.add("show")

const hideConsentSettingsPanel = () =>
  document.querySelector(".cookie-consent-settings-panel").classList.remove("show")

const toggleActiveButton = (buttons) => {
  document.querySelector(`.cookie-consent-settings-panel-btn-${buttons.on}`).classList.add("active")
  document
    .querySelector(`.cookie-consent-settings-panel-btn-${buttons.off}`)
    .classList.remove("active")
}
