import "./index.css"
import {Controller as BaseController} from "stimulus"
import Chart from "chart.js/auto"

export class Controller extends BaseController {
  static targets = ["chart"]

  connect() {
    const {url} = this.chartTarget.dataset
    let config = {
      options: {
        responsive: true,
        scales: {
          x: {
            type: "time",
            time: {
              parser: true,
              unit: "day",
              tooltipFormat: "D MMM YYYY",
              displayFormats: {
                day: "D MMM",
              },
              distribution: "series",
            },
          },
        },
      },
    }

    fetch(url)
      .then((response) => {
        const {status} = response
        if (status === 500) throw new Error("La récupération des données du graphique a échouée.")

        return response.json()
      })
      .then(({datasets, yScale, type}) => {
        if (datasets.length > 0) {
          this.element.classList.remove("hide")
        }

        const chartParams = {
          type: type,
          options: {
            ...config.options,
            scales: {
              ...config.options.scales,
              y: {
                ...yScale,
                ticks: {
                  ...yScale.ticks,
                  callback: function (value, index, values) {
                    if (index === values.length - 1) return ""

                    return value
                  },
                },
              },
            },
          },
          data: {datasets},
        }

        this.chart = new Chart(this.chartTarget, chartParams)
      })
      .catch((e) => console.error(e))
  }

  disconnect() {
    if (this.chart) this.chart.clear()
  }
}
