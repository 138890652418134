import { Controller as BaseController } from "stimulus"

export class Controller extends BaseController {
  static targets = ["submitButton"]

  submit(event) {
    event.preventDefault()
    this.submitButtonTarget.disabled = true

    const formElement = event.currentTarget
    const data = new URLSearchParams(new FormData(formElement))

    fetch(formElement.action, {
      method: "POST",
      body: data,
    })
    .then((response) => {
      if (response.status != 200) throw new Error("Une erreur a empêchée d'enregistrer la réalisation de l'exercice")
      this.submitButtonTarget.remove()
      formElement.innerHTML = "<p>Merci</p>"
    })
    .catch((error) => {
      alert(error.message)
      this.submitButtonTarget.disabled = false
    })
  }
}
