import {Controller as BaseController} from "stimulus"
import "tom-select/dist/css/tom-select"
import TomSelect from "tom-select"

export class Controller extends BaseController {
  static targets = [
    "select",
    "submitButton",
    "errorMessages",
    "scoreValue",
    "legend",
    "feedbackSelect",
    "exampleContainer",
    "shareAnswers",
    "mainConclusion",
    "questionnaire",
  ]
  static values = {
    customAttributes: Array,
  }

  connect() {
    this.enableTS()
    if (this.hasFeedbackSelectTarget) this._setupFeedbackSelects()
  }

  handleNextExample(e) {
    const {nextButtonExampleIndex, nextButtonPartIndex} = e.target.dataset
    const nextExampleIndex = (parseInt(nextButtonExampleIndex, 10) + 1).toString()
    const nextPartIndex = (parseInt(nextButtonPartIndex, 10) + 1).toString()

    const nextExampleContainer = this.exampleContainerTargets.find((container) => {
      const {exampleIndex, partIndex} = container.dataset

      // hide current container
      if (nextButtonExampleIndex === exampleIndex && partIndex === nextButtonPartIndex) {
        container.classList.add("hide")
      }

      // hide part conclusion if next container is in next part
      if (exampleIndex === "0" && partIndex === nextPartIndex) {
        this._hidePartConclusion(nextButtonPartIndex)
      }

      return (
        (nextExampleIndex === exampleIndex && partIndex === nextButtonPartIndex) ||
        (exampleIndex === "0" && partIndex === nextPartIndex)
      )
    })

    if (nextExampleContainer) {
      nextExampleContainer.classList.remove("hide")
    } else {
      e.target.innerHTML = "Merci"
      e.target.classList.add("disabled")

      this._submitCompleteExerciseEvent()
    }
  }

  _submitCompleteExerciseEvent() {
    const {completeExerciseUrl, contentId} = this.questionnaireTarget.dataset
    const authenticityToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content")

    const formData = new FormData()
    formData.append("authenticity_token", authenticityToken)
    formData.append("complete_exercise[content_type]", "Content")
    formData.append("complete_exercise[content_id]", contentId)

    fetch(completeExerciseUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 500) throw new Error("Un problème est survenu.")

        return response
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleSubmitQuestionAnswer(e) {
    const {questionIndex: currentQuestionIndex} = e.target.dataset
    const inputElement = this.element.querySelector(
      `[data-question-input-index="${currentQuestionIndex}"]`,
    )
    const {value: inputValue} = inputElement.querySelector("input")
    const feedbacks = this.element.querySelectorAll(
      `[data-feedback-question-index="${currentQuestionIndex}"]`,
    )

    feedbacks.forEach((element) => {
      const {
        dataset: {showOnMin, showOnMax},
      } = element

      if (inputValue >= parseInt(showOnMin) && inputValue <= parseInt(showOnMax)) {
        // show feedback
        element.classList.remove("hide")
        // hide current button
        e.target.classList.add("hide")

        inputElement.classList.add("disable-slider")

        const submitButton = this.submitButtonTargets.find(
          ({dataset: {questionIndex}}) => currentQuestionIndex === questionIndex,
        )

        submitButton.classList.remove("hide")
      } else {
        element.remove()
      }
    })
  }

  _setupFeedbackSelects() {
    this.feedbackSelectTargets.forEach((element) => {
      const select = new TomSelect(element, {
        maxItems: 1,
      })

      select.on("change", (value) => {
        const {exampleIndex, partIndex} = select.input.dataset
        const feedback = this.element.querySelector(
          `[data-show-on="${value}"][data-example-index="${exampleIndex}"][data-part-index="${partIndex}"]`,
        )
        const nextButton = this.element.querySelector(
          `[data-next-button-example-index="${exampleIndex}"][data-next-button-part-index="${partIndex}"]`,
        )

        feedback.classList.remove("hide")
        nextButton.classList.remove("disabled")
        select.disable()

        this._showPartConclusion(partIndex)
        this._showMainConclusion()
      })
    })
  }

  _showMainConclusion() {
    if (!this.hasMainConclusionTarget) return

    const selectElements = this.element.querySelectorAll(".feedback-select")
    const showMainConclusion = Array.from(selectElements).reduce(
      (result, element) => element.value !== "" && result,
      true,
    )

    if (showMainConclusion) {
      this.mainConclusionTarget.classList.remove("hide")
    }
  }

  _showPartConclusion(partIndex) {
    const conclusion = this._getConclusion(partIndex)
    const show = this._shouldShowConclusion(partIndex)

    if (show && conclusion) {
      conclusion.classList.remove("hide")
    }
  }

  _hidePartConclusion(partIndex) {
    const conclusion = this._getConclusion(partIndex)

    if (conclusion) {
      conclusion.classList.add("hide")
    }
  }

  _getConclusion(partIndex) {
    const conclusion = this.element.querySelector(`[data-conclusion-part-index="${partIndex}"]`)
    if (!conclusion) return

    return conclusion
  }

  _shouldShowConclusion(partIndex) {
    const selectElements = this.element.querySelectorAll(`[data-part-index="${partIndex}"]`)
    const show = Array.from(selectElements).reduce(
      (result, element) => element.value !== "" && result,
      true,
    )

    return show
  }

  enableTS() {
    this.selectTargets.forEach((element) => {
      const select = new TomSelect(element, {
        maxItems: null,
      })

      if (this.customAttributesValue.includes(select.inputId)) {
        select.on("change", (values) => {
          const customValuesContainer = this.element.querySelector(
            `#custom-${select.inputId}-container`,
          )
          const showOn = customValuesContainer.attributes.show_on.nodeValue
          const customInput = this.element.querySelector(".custom-input")

          if (values.includes(showOn)) {
            customValuesContainer.classList.remove("hide")
            customInput.classList.remove("disable")
          } else {
            customValuesContainer.classList.add("hide")
            customInput.classList.add("disable")
          }
        })
      }
    })
  }

  submit(event) {
    event.preventDefault()
    const formElement = event.currentTarget
    const data = new URLSearchParams(new FormData(formElement))
    const {formIndex} = formElement.dataset

    if (formIndex) {
      this.submitButton = this.submitButtonTargets.find(
        ({dataset: {questionIndex}}) => formIndex === questionIndex,
      )
      this.sliderInput = this.element.querySelector(`[data-question-input-index="${formIndex}"]`)
      this.textInput = Array.from(this.element.querySelectorAll(".custom-single-input")).find(
        (element) => element.dataset.questionInputIndex === formIndex,
      )
    } else {
      this.submitButton = this.submitButtonTarget
    }

    this.errorMessagesTarget.classList.add("d-none")
    this.errorMessagesTarget.innerHTML = ""
    this.submitButton.disabled = true
    if (this.textInput) this.textInput.disabled = true

    let responseStatus

    fetch(formElement.action, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        responseStatus = response.status
        if (responseStatus === 500)
          throw new Error("Un problème a empêché votre score d'être envoyé.")

        return response.json()
      })
      .then(({score, errors}) => {
        switch (responseStatus) {
          case 201:
            this._onSuccess(score)
            break
          case 422:
            const errorMessage = errors.join("</br>")
            this._onFailure(errorMessage)
            break
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  _onSuccess(score) {
    const multiSelects = this.element.querySelectorAll(".select-value.multi")
    const singleSelects = this.element.querySelectorAll(".select-value.single")
    const textInputs = this.element.querySelectorAll(".custom-input")

    multiSelects.forEach((select) => select.classList.add("disable"))
    singleSelects.forEach((select) => select.classList.add("disable"))
    textInputs.forEach((input) => input.classList.add("disable"))

    if (this.textInput) this.textInput.disabled = true

    this.submitButton.value = "Merci"

    if (score !== undefined) {
      this.scoreValueTarget.classList.remove("d-none")
      this.scoreValueTarget.innerHTML = `Votre score : ${score}`
    }

    if (this.hasLegendTarget) {
      this.legendTarget.classList.remove("d-none")
    }

    if (this.hasShareAnswersTarget) {
      this.shareAnswersTarget.querySelector("input[type='checkbox']").disabled = true
    }

    const sliderElements = this.element.querySelectorAll(".slider-value")
    sliderElements.forEach((sliderElement) => sliderElement.classList.add("disable-slider"))
  }

  _onFailure(message) {
    this.errorMessagesTarget.classList.remove("d-none")
    this.errorMessagesTarget.innerHTML = message
    this.submitButton.disabled = false
    this.sliderInput?.classList?.remove("disable-slider")

    if (this.textInput) this.textInput.disabled = false

    const offsets = this.errorMessagesTarget.getBoundingClientRect()
    const top = window.pageYOffset + offsets.top - 200
    window.scroll(0, top)
  }
}
