import {Controller} from "@hotwired/stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  refreshPage = null

  connect() {
    this.refreshPage = setInterval(() => {
      Turbolinks.visit(location.toString())
    }, 500)
  }

  disconnect() {
    clearInterval(this.refreshPage)
  }
}
