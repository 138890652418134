import "./index.scss"
import "plyr/dist/plyr.css"
import {Controller as BaseController} from "stimulus"
import Plyr from "plyr"

export class Controller extends BaseController {
  static targets = ["audioPlayer", "audioDuration", "playButton"]
  static values = {
    controls: Array
  }

  connect() {
    this.player = new Plyr(this.audioPlayerTarget, {
      controls: this.controlsValue,
    })

    this.player.on("loadeddata", (e) => {
      this.audioDurationTarget.innerHTML = this._formatDuration(e.detail.plyr.duration)
    })

    this.player.on("playing", () => {
      if (this.playButtonTarget.textContent == "Recommencer") return

      this.playButtonTarget.innerHTML = "Recommencer"
    })
  }

  restart(event) {
    event.preventDefault()

    this.player.restart()
    this.player.play()
  }

  _formatDuration(duration) {
    if (duration < 60) return [duration | 0, "secondes"].join(" ")

    const secondes = duration % 60
    const minutes = (duration / 60) | 0
    return [secondes > 50 ? minutes + 1 : minutes, "minutes"].join(" ")
  }
}
