import {Controller} from "@hotwired/stimulus"
import DirtyForm from "dirtyForm"

export default class extends Controller {
  connect() {
    this.dirtyForm = new DirtyForm(this.element, {
      message:
        "Vous n'avez pas enregistré. Si vous avez des modifications en cours, elles seront perdues si vous quittez cette page. Êtes vous sur ?",
    })
  }

  setFormAsDirty() {
    this.dirtyForm.setAsDirty()
  }
}
