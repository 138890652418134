import {Controller} from "@hotwired/stimulus"
import "tom-select/dist/css/tom-select"
import "tom-select/dist/css/tom-select.bootstrap4"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    if (this.hasSelectTarget) {
      this.enableTS()
    }
  }

  enableTS() {
    this.selectTargets.forEach((element) => {
      new TomSelect(element, {
        allowEmptyOption: true,
      })
    })
  }
}
