import { Controller } from "stimulus"
import Slider from "bootstrap-slider"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    new Slider(this.inputTarget)
  }
}
