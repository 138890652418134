import { Controller as BaseController } from "stimulus"
import rater from "rater-js"

export class Controller extends BaseController {
  static targets = ["judgement", "usefulness", "judgementInput", "usefulnessInput", "submitButton", "errorMessages"]

  connect() {
    const defaultOptions = {
      max: 5,
      step: 1,
      showTooltip: true,
      starSize: 30,
    }

    this.toggleSubmitButton()

    this.judgementRater = rater({
      element: this.judgementTarget,
      rateCallback: (rating, done) => {
        this.judgementRater.setRating(rating)
        this.judgementInputTarget.value = rating
        done()
        this.toggleSubmitButton()
      },
      ...defaultOptions,
    })

    this.usefulnessRater = rater({
      element: this.usefulnessTarget,
      rateCallback: (rating, done) => {
        this.usefulnessRater.setRating(rating)
        this.usefulnessInputTarget.value = rating
        done()
        this.toggleSubmitButton()
      },
      ...defaultOptions,
    })
  }

  toggleSubmitButton() {
    const usefulnessValue = this.usefulnessInputTarget.value
    const judgementValue = this.judgementInputTarget.value

    if (usefulnessValue == "" || judgementValue == "") {
      this.submitButtonTarget.classList.add("hide")
    } else {
      this.submitButtonTarget.classList.remove("hide")
    }
  }

  disconnect() {
    if (this.judgementRater) this.judgementRater.clear()
    if (this.usefulnessRater) this.usefulnessRater.clear()
  }

  submit(event) {
    event.preventDefault()
    const formElement = event.currentTarget
    const data = new URLSearchParams(new FormData(formElement));

    this.submitButtonTarget.disabled = true
    this.errorMessagesTarget.classList.add("d-none")
    this.errorMessagesTarget.innerHTML = ""

    let responseStatus

    fetch(formElement.action, {
      method: "POST",
      body: data,
    })
    .then((response) => {
      responseStatus = response.status
      if (responseStatus === 500)
        throw new Error("Un problème a empêché votre avis d'être posté.")

      return response.json()
    })
    .then((response) => {
      switch(responseStatus) {
        case 201:
          this.submitButtonTarget.remove()
          this.judgementRater.disable()
          this.usefulnessRater.disable()
          break
        case 422:
          this.submitButtonTarget.disabled = false
          this.errorMessagesTarget.classList.remove("d-none")
          this.errorMessagesTarget.innerHTML = response.join("</br>")
          break
      }
    })
    .catch((error) => {
      this.errorMessagesTarget.classList.remove("d-none")
      this.errorMessagesTarget.textContent = error.message
      this.submitButtonTarget.disabled = false
    })
  }
}
