import "./index.scss"
import {DirectUpload} from "@rails/activestorage"
import {Controller as BaseController} from "stimulus"

export class Controller extends BaseController {
  static targets = ["input", "filename", "preview", "progressBar"]

  connect() {
    this.input = this.inputTarget
    this.filename = this.filenameTarget
    this.progressBar = this.progressBarTarget
  }

  change(event) {
    this._resetAndShowProgress()
    Array.from(this.input.files).forEach((file) => this._uploadFile(file))
    this.input.value = null
  }

  _uploadFile(file) {
    const url = this.input.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        alert("Une erreur est survenue lors de l'envoi du fichier.")
      } else {
        //  Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement("input")
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("value", blob.signed_id)
        hiddenField.name = this.input.name
        document.querySelector("form").appendChild(hiddenField)
        this.filename.innerHTML = blob.filename
        if (this.hasPreviewTarget) {
          this.previewTarget.style.opacity = "0.25"
        }
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const progress = Math.round((event.loaded / event.total) * 100)
    this._setProgress(progress)

    if (progress === 100) this.progressBar.firstChild.classList.remove("progress-bar-animated")
  }

  _setProgress(value) {
    this.progressBar.firstChild.style.width = `${value}%`
    this.progressBar.firstChild.setAttribute("aria-valuenow", value)
  }

  _resetAndShowProgress() {
    this._setProgress(0)
    this.progressBar.firstChild.classList.add("progress-bar-animated")
    this.progressBar.style.display = "flex"
  }
}
